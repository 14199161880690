import makeStyles from '@material-ui/core/styles/makeStyles';
import { NEUTRAL_600, NEUTRAL_800, PRIMARY } from '@theme/colors';
import Link from 'next/link';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
    wrapper: {
        padding: '0 16px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '&.fullpage': {
            width: '100vw',
            height: '100vh',
        },
    },
    image: {
        maxWidth: '100%',
        marginBottom: 16,
    },
    errorCode: {
        color: PRIMARY,
        fontSize: 18,
        fontWeight: 700,
        lineHeight: '28px',
        textAlign: 'center',
        margin: '0 0 8px 0',
    },
    title: {
        color: NEUTRAL_800,
        fontSize: 48,
        fontWeight: 700,
        lineHeight: '64px',
        textAlign: 'center',
        margin: '0 0 16px 0',
    },
    desc: {
        color: NEUTRAL_600,
        fontSize: 18,
        fontWeight: 400,
        lineHeight: '28px',
        textAlign: 'center',
        margin: 0,
    },
    action: {
        background: PRIMARY,
        padding: '14px 26px',
        borderRadius: 6,

        color: 'white',
        fontSize: 16,
        fontWeight: 700,
        lineHeight: '20px',
        marginTop: 40,
    },
}));

const Error404 = (props) => {
    const {
        title = 'Page not found',
        desc = 'Sorry, we couldn\'t find the page you\'re looking for.',
        imgSrc = '/assets/img/404.png',
        withAction = true,
        actionLabel = 'Back to home',
        actionUrl = '/',
        fullPage = false,
    } = props;
    const styles = useStyles();

    return (
        <div className={clsx(styles.wrapper, fullPage && 'fullpage')}>
            {imgSrc && <img alt="404 Not Found" src={imgSrc} className={styles.image} />}
            <div>
                <p className={styles.errorCode}>404</p>
                <h1 className={styles.title}>{title}</h1>
                <h2 className={styles.desc}>{desc}</h2>
            </div>
            {withAction && (
                <Link href={actionUrl}>
                    <a className={styles.action}>{actionLabel}</a>
                </Link>
            )}
        </div>
    );
};

export default Error404;
