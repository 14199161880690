import { useMutation, useLazyQuery, useQuery } from '@apollo/client';
import * as Schema from '@modules/login/services/graphql/schema';

const contextInternal = {
    context: { request: 'internal' },
};
const fetchPolicy = {
    fetchPolicy: 'cache-and-network',
};

export const getToken = () => useMutation(Schema.getCustomerToken, {
    ...contextInternal,
});

export const removeToken = () => useMutation(Schema.removeToken, {
    ...contextInternal,
});

export const getCustomer = (options) => useLazyQuery(Schema.getCustomer, {
    ...options, ...contextInternal, fetchPolicy: 'no-cache',
});

export const getSeller = (options) => useLazyQuery(Schema.getSeller, {
    ...options, ...contextInternal, fetchPolicy: 'no-cache',
});

export const getStoreConfig = (variables) => useQuery(Schema.getStoreConfig, {
    variables, ...contextInternal, ...fetchPolicy,
});

export const getAdAccountId = (options) => useLazyQuery(Schema.getAdAccountId, {
    ...options, ...fetchPolicy,
});

export default {
    getToken,
    removeToken,
    getCustomer,
    getSeller,
    getStoreConfig,
    getAdAccountId,
};
