export const MAX_WIDTH = '1440px';
export const BREAKPOINTS = {
    xs: 0,
    sm: 768,
    middleView: 990,
    md: 1024,
    lg: 1200,
    xl: 1920,
};
export default {};
