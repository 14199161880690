/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const storeConfig = `
    {
        storeConfig {
            store_name
        }
    }
`;

export const storeConfigPim = `
    {
        storeConfig {
            weight_unit
            dimension_unit
            enable_company_create_variant
            product_image_min_width
            product_image_min_height
            product_image_max_size
            product_image_max_limit
            katalis_application_enable
            katalis_purchase_credit_url
            upload_product_limit
        }
    }
`;

export const availableStores = gql(`
query AvailableStores {
    availableStores {
        store_code
        store_name
        locale
        is_default_store
    }
}`);
